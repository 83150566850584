/* Footer 컴포넌트 */
.footer {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  background-color: #000;
  color: #fff;
}

/* 회사 정보 */
.footer-company {
  display: flex;
  flex-direction: column;
  .footer-logo {
    margin-bottom: 20px;
  }
  .footer-title {
    font-size: 18px;
    font-weight: bold;
  }
  .footer-detail {
    font-size: 14px;
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

/* 개인정보처리방침 및 이용약관 */
.footer-info {
  display: flex;
  gap: 50px;
  .button {
    white-space: nowrap;
  }
}

@media (max-width: 1024px) {
  .footer-info {
    display: flex;
    gap: 24px;
  }
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 24px;
  }
}
