.html,
body {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #121212;
}

html {
  /* scroll-behavior: smooth; */
}
html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}


.body {
  width: 100%;
  height: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-decoration: none;
  color: #fff;
  box-sizing: border-box;
  font-size: 16px;
}
*:focus {
  outline: 0;
  outline: none;
}
ul {
  list-style: none;
}

th {
  font-weight: normal;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
}

input {
  outline: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  appearance: none;
}
input[type="submit"] {
  cursor: pointer;
}
img {
  display: block;
}

