.success-modal{
  max-width: 420px;
  width: 100%;
  border:2px solid #539df1;
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  background: #000;
  z-index: 10;
  .success-modal__title{
    text-align: center;
    line-height: 24px;
  }
  .success-modal__btn{
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #fff;
    transition: 0.3s;
    border: 1px solid #539df1;
    background: #000;
    &:hover{
      background: #539df1;
      border: 1px solid #539df1;
      color: #000;
    }
  }
}
.success-modal__bg{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  background: #000;
  z-index: 3;
  opacity: 0.7;
  transition: 0.3s;
}