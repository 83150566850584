/* header 컴포넌트 */
.header {
  position: relative;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  &.active {
    .header-nav-section {
      background: rgba(0, 27, 58, 0.5);
    }
  }
}

/* nav 로고 */
.logo img {
  height: 40px;
}

/* nav */
.header-nav-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 110px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(18, 18, 18, 0.7);
  z-index: 1000;
  transition: 0.3s;
}

/* nav 항목들 */
.header-nav-links {
  list-style: none;
  align-items: center;
  display: flex;
  gap: 50px;
  a {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Lato", sans-serif;
    font-size: 15px;
    position: relative;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    &.active {
      color: #539df1;
      &::before {
        width: 100%;
      }
    }
  }
}

/* nav 항목들 애니메이션 */
.header-nav-links.SMN_effect-6 a {
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
}

.header-nav-links.SMN_effect-6 a:before {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 10px 0;
  max-width: 0;
  border-bottom: 2px solid #539df1;
  color: #539df1;
  content: attr(data-hover);
  transition: max-width 0.5s;
  white-space: nowrap;
}

.header-nav-links.SMN_effect-6 a:hover:before,
.header-nav-links.SMN_effect-6 a:focus:before {
  max-width: 100%;
}

/* 반응형 toggle 메뉴 열렸을 때, nav 요소들을 제외한 배경 부분 */
.menu-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  z-index: 3;
  opacity: 0.3;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .header {
    .header-nav-section__menu-btn {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 30px;
      height: 24px;
      cursor: pointer;
      &.active {
        span {
          &.line1 {
            transform: rotate(135deg);
            top: 13px;
          }
          &.line2 {
            width: 0;
            height: 0;
          }
          &.line3 {
            transform: rotate(-135deg);
            top: 13px;
          }
        }
      }
      span {
        display: block;
        width: 30px;
        height: 3px;
        background: #fff;
        transition: 0.3s;
        &.line1 {
          position: absolute;
          top: 0px;
          left: 0;
        }
        &.line2 {
          position: absolute;
          top: 10px;
          left: 0;
        }
        &.line3 {
          position: absolute;
          top: 20px;
          left: 0;
        }
      }
    }
    &.active {
      .header-nav-section {
        background: rgba(0, 27, 58, 1);
      }
      .header-nav-links {
        background: rgba(0, 27, 58, 1);
      }
    }
    .header-nav-links {
      position: absolute;
      right: -200px;
      top: 0;
      flex-direction: column;
      background: #000;
      z-index: 10;
      padding: 40px 20px 20px;
      top: 60px;
      transition: 0.5s;
      gap: 30px;
      &.active {
        right: 0;
      }
    }
    .header-nav-section {
      height: 60px;
    }
  }

  .main {
    .visual-title-section {
      top: 60px !important;
    }
    .video-background {
      height: calc(100vh - 60px) !important;
    }
    .visual-title-section::before {
      height: calc(100vh - 60px) !important;
    }
  }
}
