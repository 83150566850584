.main {
  /* 고정된 Contact 버튼 --> 제외(11/8) 
  .contact-button-fixed {
    display: block;
    position: fixed;
    top: 30%;
    right: 20px;
    width: 120px;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    z-index: 3;
    transition: all 0.2s;
  }

  /* Contact 버튼에 대한 색상 및 애니메이션 효과 
  .contact-button-fixed.btnLightBlue { 
    background: #539df1;
    box-shadow: 0px 5px 0px 0px #2b517c;
  }

  .contact-button-fixed.btnPush:hover {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .contact-button-fixed.btnLightBlue.btnPush:hover {
    box-shadow: 0px 0px 0px 0px #2b517c;
  }
    */

  /* 웹페이지 상단 Section*/
  /* 비디오 부분 */
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 110px);
    object-fit: cover;
    z-index: -1;
  }

  .visual-title-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 110px);
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 0;
  }

  /* 타이틀 부분 */
  .visual-title-section {
    top: 110px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    .visual-title {
      margin: 50px 0;
      color: #fff;
      text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
      position: absolute;
      left: calc(50% - -20px);
      top: calc(50% - 110px);
      width: 100%;
      transform: translate(-50%, -50%);
      p {
        font-size: 78px;
        span {
          color: #539df1;
          font-size: 78px;
          text-shadow: 0px 8px 8px rgb(0, 0, 0, 0.5);
          font-weight: bold;
        }
      }
    }
  } /* 상단 Section END ---------------------------------------------------- */

  /* Smart Factory Section */
  .smart-factory {
    width: 100%;
    text-align: center;
    padding: 120px 20px;
    background-color: #121212;
    color: white;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    .smart-factory-title {
      font-size: 58px;
      font-weight: bold;
      color: #539df1;
      margin-bottom: 50px;
    }
    .smart-factory-description {
      font-size: 24px;
      margin-bottom: 60px;
      color: #ffffff;
      margin-top: 50px;
      margin-bottom: 100px;
      p {
        margin: 10px 0;
        font-size: 24px;
        span {
          font-size: 24px;
          color: #539df1;
          font-weight: bold;
        }
      }
    }
  }

  /* Smart Factory 원 3개 부분 */
  .smart-factory-features {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .line {
      flex: 1;
      height: 2px;
      background-color: #fff;
    }
    .feature-circle {
      width: 400px;
      height: 400px;
      border: 2px solid #fff;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 2;
      p {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        margin: 0;
        margin-bottom: 35px;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
    /* 가운데 원은 앞쪽에 오도록, 양쪽 원은 겹치게 조정 */
    .feature-circle:nth-child(3) {
      margin-left: -50px;
      margin-right: -50px;
      z-index: 2; /* 가운데 원을 앞으로 가져오기 */
    }
    .feature-circle:nth-child(1),
    .feature-circle:nth-child(3) {
      z-index: 1; /* 양쪽 원을 뒤로 배치 */
    }
  }

  /* Smart Factory 하단 이미지 */
  .smart-factory .background img {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 200px;
  } /* Smart Factory Section END ---------------------------------------- */

  /* Service Section */
  .service {
    width: 100%;
    text-align: center;
    padding: 120px 20px;
    background-color: #121212;
    color: white;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    .service-title {
      font-size: 58px;
      font-weight: bold;
      color: #539df1;
      margin-bottom: 50px;
    }
    .service-description {
      font-size: 24px;
      margin-bottom: 60px;
      color: #ffffff;
      margin-top: 50px;
      margin-bottom: 100px;
      p {
        margin: 10px 0;
        font-size: 24px;
        span {
          font-size: 24px;
          color: #539df1;
          font-weight: bold;
        }
      }
    }
  }

  /* Service ERP+MES 5분류 이미지 */
  .service-feature img {
    width: 1000px;
    margin: 0 auto;
  } /* Service Section END ------------------------------------------------ */

  /* Effect Section */
  .effect {
    width: 100%;
    text-align: center;
    padding: 120px 20px;
    background-color: #121212;
    color: white;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    .effect-title {
      font-size: 58px;
      font-weight: bold;
      color: #539df1;
      margin-bottom: 50px;
    }
    .effect-description {
      font-size: 24px;
      margin-bottom: 60px;
      color: #ffffff;
      margin-top: 50px;
      margin-bottom: 100px;
      p {
        margin: 10px 0;
        font-size: 24px;
        span {
          font-size: 24px;
          color: #539df1;
          font-weight: bold;
        }
      }
    }
  }

  /* Effect card 3개 */
  .cards {
    display: flex;
    gap: 50px;
    justify-content: center;
    /* card 1개 모양 */
    .card {
      width: 420px;
      height: 550px;
      border: 2px solid #539df1;
      border-radius: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: start;
      box-sizing: border-box;
      padding: 20px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      .num {
        font-size: 20px;
      }
      .title {
        font-size: 32px;
        font-weight: bold;
        span {
          color: #539df1;
          font-size: 22px;
        }
      }
      .detail {
        font-size: 16px;
        margin-top: 20px;
      }
      .btns {
        margin: 15px 0;
        display: flex;
        gap: 10px;
        .btn {
          padding: 5px 10px;
          border: 1px solid #539df1;
          border-radius: 25px;
          background-color: #121212;
          color: #fff;
          font-size: 16px;
        }
      }
      .card-icon {
        position: absolute;
        bottom: 20px;
        right: 20px;
        img {
          width: 200px;
        }
      }
    }
  }

  /* 2번째 카드만 위치 조정 */
  .card:nth-child(2) {
    margin-top: 100px;
  }

  /* 각 card hover시 애니메이션 */
  .card:hover {
    transform: translateY(-20px);
  } /* Effect Section END ------------------------------------------------ */

  /* About Section */
  .about {
    width: 100%;
    text-align: center;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0;
    .about-title {
      font-size: 58px;
      font-weight: bold;
      color: #539df1;
      margin-bottom: 100px;
      z-index: 1; /* 텍스트가 배경보다 위에 오도록 설정 */
    }
    .about-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 1000px;
      z-index: 1;
      .about-description {
        h2 {
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 15px;
          text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
          margin-bottom: 80px;
        }
        p {
          font-size: 28px;
          margin: 10px 0;
          span {
            font-size: 28px;
            font-weight: bold;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
          }
        }
        .first {
          margin-bottom: 50px;
        }
      }
    }
  }

  .about-cards-cover {
    width: 100%;
    padding: 0 20px;
    .about-cards {
      margin-top: 150px;
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1200px;
      flex-wrap: wrap;
      margin: 150px auto 0;
      .about-card {
        display: flex;
        width: calc((100% - 160px) / 4);
        min-height: 220px;
        flex-direction: column;
        border: 2px solid #539df1;
        border-radius: 15px;
        text-align: start;
        justify-content: center;
        align-items: center;
        position: relative;
        background: linear-gradient(135deg, #121212, #121212);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }
    }
  }

  .about-icon img {
    width: 50px;
    transition: transform 0.3s ease;
  }

  .about-card:hover {
    animation: background-shift 1s forwards; /* 배경 애니메이션 시작 */
    transform: translateY(-10px) scale(1.05); /* 카드 크기 확대와 위로 이동 */
    .about-icon img {
      transform: rotate(10deg) scale(1.1);
    }
  }

  .about-type {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .about-detail {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  /* history 컴포넌트 */
  .history {
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
    gap: 200px;
    width: 100%;
    padding: 0 20px;
    /* 주요개발내역 */
    .history-develop {
      text-align: start;
      flex: 1;

      .history-develop-title {
        font-weight: bold;
        font-size: 34px;
        color: #539df1;
      }

      .history-develop-detail {
        margin-top: 50px;
        font-size:18px;
      }
    }

    /* 연혁 */
    .history-year {
      text-align: start;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 100px;

      .history-year-title {
        font-weight: bold;
        font-size: 38px;
        color: #539df1;
      }

      .history-year-detail {
        margin-top: 30px;
        display: flex;
        gap: 50px;

        .history-year-detail-month {
          width: 30px;
          color: #539df1;
          font-size: 28px;
        }

        .history-year-detail-sub {
          font-size: 18px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {
            font-size: 20px;
            color: #c6c6c6;
          }
        }
      }
    }
  }
  .history-year-detail-sub-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .contact-us {
    width: 100%;
    // margin-top: 200px;
    // height: 1600px;
    padding: 120px 20px;
    background-color: #121212;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-title {
    font-size: 58px;
    font-weight: bold;
    color: #539df1;
    margin-bottom: 100px;
  }

  .contact-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid #539df1;
    padding: 40px;
    border-radius: 10px;
    max-width: 1000px;
    width: 100%;
  }

  .contact-info {
    flex: 1;
    font-size: 18px;
  }

  .contact-info-title {
    font-size: 30px;
    font-weight: bold;
  }

  .contact-info-detail p {
    margin-bottom: 20px;
    font-size: 20px;
    strong {
      width: 50px;
      display: inline-block;
    }
  }

  .contact-info-detail {
    margin: 50px 0;
  }

  .contact-form {
    flex: 1;
    .contact-form__textarea-box {
      .err-txt {
        bottom: -20px !important;
      }
    }
    .contact-form__input-box,
    .contact-form__textarea-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-bottom: 10px;
      .err-txt {
        position: absolute;
        left: 100px;
        bottom: 0;
        font-size: 12px;
      }
      label {
        width: 122px;
      }
      input,
      textarea {
        width: 100%;
      }
    }
  }

  .form-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
  }

  .tab {
    padding: 5px 20px;
    border: 1px solid #539df1;
    background-color: transparent;
    color: #539df1;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }

  .tab.active {
    background-color: #539df1;
    color: #121212;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  input {
    margin-bottom: 15px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    padding: 10px;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    background-color: #121212;
    color: #fff;
  }

  textarea {
    resize: none;
  }

  .checkbox {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 20px 0;
  }

  .checkbox input {
    margin: 0; /* 체크박스와 텍스트의 간격 조정 */
  }

  .checkbox label {
    margin: 0;
    padding-top: 2px; /* 수직 중앙 정렬 미세 조정 */
    font-size: 16px; /* 텍스트 크기 조정 */
    color: #fff;
  }

  .submit-button {
    padding: 10px 10px;
    background-color: #121212;
    color: #539df1;
    border: 1px solid #539df1;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    background-color: #539df1;
    color: #000;
  }

  .contact-info-detail strong {
    margin-right: 20px;
  }

  .err-txt {
    font-size: 14px;
    margin-top: -12px;
    margin-bottom: 8px;
    color: rgb(201, 13, 13);
  }
} //main--END--

@media (max-width: 1200px) {
  .main {
    .visual-title {
      font-size: 66px !important;
      p {
        font-size: 66px !important;
        span {
          font-size: 66px !important;
        }
      }
    }
    .smart-factory-title,
    .service-title,
    .about-title,
    .contact-title {
      font-size: 3em !important; 
    }
    .smart-factory-description {
      font-size: 24px !important;
    }
    .smart-factory-features {
      &::after,
      &::before {
        display: none;
      }
      max-width: 100%;
      padding: 0 20px;
      .feature-circle {
        width: 350px;
        height: 350px;
        p {
          font-size: 24px;
        }
      }
    }

    .service-description {
      p {
        font-size: 24px;
        span {
          font-size: 24px;
        }
      }
    }
    .service-feature {
      padding: 0 20px ;
      img {
        width: 100%;
      }
    }
    .effect-title {
      font-size: 3em !important;
    }
    .effect-description {
      font-size: 24px ;
    }
    .card {
      .title {
        font-size: 20px !important;
      }
      .detail {
        font-size: 16px;
      }
    }
    .history {
      gap: 30px;
      justify-content: space-between;
      .history-develop {
        flex: 1;
        .history-develop-title {
          font-size: 32px;
        }
        .history-develop-detail {
          font-size: 16px;
          margin-top: 16px;
        }
      }
      .history-year {
        flex: 1;
        .history-year-title {
          font-size: 32px;
        }
        .history-year-detail-month {
          font-size: 24px;
        }
        .history-year-detail-sub {
          font-size: 16px;
          span {
            font-size: 18px;
          }
        }
      }
    }
    .contact-container {
      padding: 40px 20px;
    }
    .contact-info-title {
      font-size: 26px;
    }
    .contact-info-detail {
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main {
    .smart-factory-features {
      .feature-circle {
        width: 250px;
        height: 250px;
        p {
          font-size: 16px;
        }
        img {
          width: 100px;
          height: auto;
        }
      }
    }
    .smart-factory-description p,
    .service-description p,
    .effect-description p {
      font-size: 18px !important;
      span {
        font-size: 18px !important;
      }
    }
    .card {
      .btns {
        flex-wrap: wrap;
      }
      .btn {
        font-size: 14px;
      }
    }
    .card-icon {
      img {
        width: 140px;
      }
    }
    .cards {
      gap: 20px;
    }
    .about-cards-cover{
      .about-cards{
        .about-card{
          width: calc((100% - 80px) / 2);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main {
    .contact-button-fixed {
      width: 100px;
      line-height: 40px;
      right: 16px;
    }
    label {
      font-size: 14px;
      margin-bottom: 6px;
    }
    input {
      margin-bottom: 10px;
    }
    .visual-title {
      left: 0 !important;
      transform: translate(0, -50%) !important;
      padding: 0 20px !important;
      p {
        font-size: 42px !important;
        span {
          font-size: 42px !important;
        }
      }
    }
    .smart-factory,
    .service,
    .effect,
    .contact-us {
      padding: 120px 20px 0 !important;
    }
    .about {
      padding: 120px 0px 0 !important;
      height: auto;
      .about-description {
        padding: 0 20px !important;
        h2 {
          font-size: 42px !important;
          margin-bottom: 40px !important;
        }
        p {
          font-size: 18px !important;
          margin-bottom: 16px !important;
          span {
            font-size: 18px !important;
          }
        }
      }
    }
    .history {
      margin: 120px 0 0;
    }
    .about-content {
      padding: 50px 0;
      height: auto !important;
    }
    .visual-title {
      p {
        text-align: center;
      }
    }
    .smart-factory-features {
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      .line {
        opacity: 0;
        display: none;
      }
      .feature-circle {
        width: 200px;
        height: 200px;
        &:nth-child(3) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .smart-factory-title,
    .service-title,
    .effect-title {
      // font-size: 36px !important;
    }
    .smart-factory-description,
    .service-description,
    .effect-description {
      margin-top: 24px;
      margin-bottom: 50px;
    }
    .smart-factory-description p,
    .service-description p,
    .effect-description p {
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }
    .cards {
      flex-wrap: wrap;
      .card {
        width: 100%;
        height: 460px;
        img {
          width: 300px;
        }
        &:nth-child(2) {
          margin-top: 0;
        }
      }
    }
    .about{
      .about-description {
        h2 {
          font-size: 28px !important;
        }
        .first {
          margin: 0;
        }
        p {
          font-size: 14px !important;
          span {
            font-size: 14px !important;
          }
        }
      }
    }

    .about-cards {
      flex-wrap: wrap;
      .about-card {
        width: calc((100% - 60px) / 2);
      }
    }
    .history {
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      .history-year {
        gap: 24px;
      }
    }
    .contact-us {
      padding: 0 20px 60px;
    }
    .contact-container {
      flex-direction: column;
    }
  }
}

@media (max-width: 500px) {
  .main {
    .visual-title {
      p {
        font-size: 30px !important;
        span {
          font-size: 30px !important;
        }
      }
    }
    .smart-factory {
      .background {
        img {
          margin-top: 50px;
        }
      }
    }
    .cards {
      .card {
        img {
          width: 200px;
        }
      }
    }
    .about-cards {
      .about-card {
        width: 100%;
      }
    }
    .history {
      .history-year {
        .history-year-detail-month {
          width: 20px !important;
        }
        .history-year-detail-sub {
          font-size: 14px !important;
          margin-top: 6px;
          span {
            font-size: 14px !important;
          }
        }
      }
    }
    .about-cards-cover{
      .about-cards{
        .about-card{
          width: 100% !important;
        }
      }
    }
  }
}
